ptz-breadcrumb-item {
  display: inline-flex;
  align-items: center;

  .ptz-breadcrumb-item {
    list-style: none;
    font-weight: $petz-font-weight-bold;
    line-height: $petz-font-lineheight-sm;
    color: $petz-color-neutral-darker-accent;

    .ptz-link, a {
      color: $petz-color-primary-brand;
      text-decoration: none;
      border: none;

      &:hover {
        color: $petz-color-primary-dark;
      }
    }
  }

  .srs-breadcrumb-item {
    list-style: none;
    font-weight: $seres-font-weight-bold;
    line-height: $seres-font-lineheight-sm;
    color: $seres-color-neutral-darker-accent;

    .ptz-link, a {
      color: $seres-color-primary-brand;
      text-decoration: none;
      border: none;

      &:hover {
        color: $seres-color-primary-dark;
      }
    }
  }

  &:last-child {
    .ptz-breadcrumb-item,
    .srs-breadcrumb-item {
      a,
      a:hover {
        color: $seres-color-neutral-dark;
        pointer-events: none;
        cursor: default;
      }
    }
  }

  ptz-icon {
    position: relative;
    top: 0;

    i {
      display: flex;
    }
  }
}
