import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Size {
  Large = 'large',
  Small = 'small',
}

export const PTZBreadcrumbItemConfigList = {
  Brand: PTZBrandConfigList.Name,
  Size: EnumToArray([Size]),
};

export const PTZBreadcrumbItemConfig = {
  Brand: PTZBrandConfig.Name,
  Size,
};
