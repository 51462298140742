import { Component, h, Host, Prop } from '@stencil/core';
import { PTZBreadcrumbItemTypes } from './types/ptz-breadcrumb-item.types';
import { PTZBreadcrumbItemConfig } from './types/ptz-breadcrumb-item.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';

@Component({
  tag: 'ptz-breadcrumb-item',
  styleUrl: 'ptz-breadcrumb-item.scss',
  shadow: false
})

export class PTZBreadcrumbItem {
  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Tamanho do breadcrumb */
  @Prop() size: PTZBreadcrumbItemTypes.Size = PTZBreadcrumbItemConfig.Size.Large;

  render() {
    return (
      <Host>
        <span class={`${this.brand}-breadcrumb-item`}><slot></slot></span>
        <ptz-icon name="angle-right" variant="line" size={`${this.size === PTZBreadcrumbItemConfig.Size.Large ? 'md' : 'sm'}`}></ptz-icon>
      </Host>
    );
  }
}
